import Cookies from "js-cookie";

const TokenKey = "X-TOKEN";

export function getToken() {
  // u4
  // const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC90ZXN0LmZsLmFwaS5jcm9zc2NyZXcuY2MiLCJhdWQiOiJodHRwOlwvXC90ZXN0LmZsLmFwaS5jcm9zc2NyZXcuY2MiLCJpYXQiOjE3MDE4NzE1MDYsIm5iZiI6MTcwMTg3MTUwNiwianRpIjoiYmQzOThhZmYwMWE3NDZiYjQ2Mzg2Y2ZjNmU4ODIzNzUifQ.DtjXvOqpiP1E3jhX268v4tVR43eFP9RuETG-Pb-CERY'
  // u9
  // const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC90ZXN0LmZsLmFwaS5jcm9zc2NyZXcuY2MiLCJhdWQiOiJodHRwOlwvXC90ZXN0LmZsLmFwaS5jcm9zc2NyZXcuY2MiLCJpYXQiOjE2NTIzMTU1NDYsIm5iZiI6MTY1MjMxNTU0NiwianRpIjoiMWFkMTJhZmRlZmI2MGRjYzg3YWI4NDY1Y2E3NWMwMjYifQ.mdg1xJlCHqtE4qTJ9P8HgWkE-LSr1i14j35B-MnulfU';
  // u61
  const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC90ZXN0LmZsLmFwaS5jcm9zc2NyZXcuY2MiLCJhdWQiOiJodHRwOlwvXC90ZXN0LmZsLmFwaS5jcm9zc2NyZXcuY2MiLCJpYXQiOjE3MDIyNzk3MDgsIm5iZiI6MTcwMjI3OTcwOCwianRpIjoiYjdjZWVhMGYwNmNkOGFmYjYwYzhjMmIwMDk0MTE0ZTUifQ.UW8-oH7noiZZynImcei-B7jMmSdzSV4yXQRXWBZlYl0'
  // u70
  // const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9hcGkuZmxhc2hiYWNrLmNyb3NzY3Jldy5jYyIsImF1ZCI6Imh0dHA6XC9cL2FwaS5mbGFzaGJhY2suY3Jvc3NjcmV3LmNjIiwiaWF0IjoxNjM2NDQwMTczLCJuYmYiOjE2MzY0NDAxNzMsImp0aSI6IjhlOGVjZjE2YTkwZWI0NjNmN2YwYTdhMzA3ZDZlMmFmIn0.0D8jgIOcfrLlgnRrZrWurJkAiLrODi0l6wjnzFYJMUE'
  // u109
  // const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9hcGkuZmxhc2hiYWNrLmNyb3NzY3Jldy5jYyIsImF1ZCI6Imh0dHA6XC9cL2FwaS5mbGFzaGJhY2suY3Jvc3NjcmV3LmNjIiwiaWF0IjoxNjQ2Nzg3MTA3LCJuYmYiOjE2NDY3ODcxMDcsImp0aSI6ImY1NGNmNjVhMjg5NDdjZTM3OGMwNTMzYzI1OGM3MTk0In0.HYviOwT0t50WbUL9uOe7Ors67UHRJRYdlxkM_X5ZFXg'
  // 756
  // const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC90ZXN0LmZsLmFwaS5jcm9zc2NyZXcuY2MiLCJhdWQiOiJodHRwOlwvXC90ZXN0LmZsLmFwaS5jcm9zc2NyZXcuY2MiLCJpYXQiOjE2NTMwMTAzNzYsIm5iZiI6MTY1MzAxMDM3NiwianRpIjoiZjM4ZmRmODRhYmQ3NTFmYzgwMjBlNTU4YzMwOTgzMGIifQ.RJWbGYAe-fL9rKjyDTWsuHf4BNSDPdWbAeKh9iog8u8';
  // 1008
  // const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC90ZXN0LmZsLmFwaS5jcm9zc2NyZXcuY2MiLCJhdWQiOiJodHRwOlwvXC90ZXN0LmZsLmFwaS5jcm9zc2NyZXcuY2MiLCJpYXQiOjE2NDk3MzIzODYsIm5iZiI6MTY0OTczMjM4NiwianRpIjoiNTFkY2QyNzg4YTI1NTJkNGMyNzYzNmRjOGM0MzkxMTQifQ.r4X6z28ufsWCEaGdg-IhcqPtTpq1i78pRrVumlE5Ak4';
  // u1055
  // const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC90ZXN0LmZsLmFwaS5jcm9zc2NyZXcuY2MiLCJhdWQiOiJodHRwOlwvXC90ZXN0LmZsLmFwaS5jcm9zc2NyZXcuY2MiLCJpYXQiOjE2NTM2NjM0NzMsIm5iZiI6MTY1MzY2MzQ3MywianRpIjoiYjM0ZTJjZDVjYjc5MjQ4M2ViMTg0ZWFiMWFkNWY3NDgifQ.9a0hsorOBzyoZxH2AiqI62wumWf-u3mDMoYZKR3x-Co'
  // return token;
  // const token = ''
  // u1185
  // const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC90ZXN0LmZsLmFwaS5jcm9zc2NyZXcuY2MiLCJhdWQiOiJodHRwOlwvXC90ZXN0LmZsLmFwaS5jcm9zc2NyZXcuY2MiLCJpYXQiOjE2NTEwMzA5MTEsIm5iZiI6MTY1MTAzMDkxMSwianRpIjoiZDAwZmI2NmY2Y2I5MDBkZTczYTI2MDdiY2ViMTMwZTQifQ.CE5a6vjB1nULJGcEevyQAmJ3q0dKcJWWZ0tJvMKzVOY';
  return process.env.NODE_ENV === 'development' ? token : Cookies.get(TokenKey);
}

export function setToken(token, expires) {
  return Cookies.set(TokenKey, token, {
    expires
  });
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}


export function setCookie(key, val, expires) {
  return Cookies.set(key, val, {
    expires
  })
}

export function getCookie(key) {
  return Cookies.get(key) || null
}